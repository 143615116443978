import * as React from "react";
import { CustomerPage } from "../component/page/customer-page";
import { CustomerId } from "../type/shared";
import { useCustomer } from "../hook/data/customer";

type CustomerPageContainerOwnProps = { id?: string }

type CustomerPageContainerProps = CustomerPageContainerOwnProps

const useCustomerContainer = (customerId: CustomerId, accessToken: string) => {
  const {
    data,
    error,
    loading
  } = useCustomer(customerId, accessToken)

  return {
    customer: data,
    error,
    loading
  }
}

export const CustomerPageContainer: React.SFC<CustomerPageContainerProps> = (props) => {
  const state = useCustomerContainer("me", "")
  return <CustomerPage {...state} />
}
