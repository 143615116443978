import React from 'react';
import { css, cx } from "emotion";
import Collapsible from "../collapsible";

export interface IGeneralInfoBoxProps {
  children: Array<{
    heading: React.ReactChild;
    render: React.ReactChild;
    isOpen?: boolean;
  }>;
  className?: string;
}

const GeneralInfoBox: React.FC<IGeneralInfoBoxProps> = props => {
  return (
    <div className={cx("pa4", css({
      borderRadius: 4,
      backgroundColor: '#e9ecef',
      position: 'sticky',
      top: 100
    }), props.className)}>
      {props.children.map((child, i, arr) => {
        return (
          <div key={i}>
            <Collapsible
            isOpen={child.isOpen}
            heading={
              <div className={"flex items-center"}>
                <h3 className={css({
                  fontFamily: '"HelveticaNeueLTStd-Bd", sans-serif',
                  fontSize: '18px',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: '0.21px',
                  color: "#34475b",
                  marginTop: 5
                })}>{child.heading}</h3>
              </div>
            }>
              <div className={cx("mb-4", css({
                fontFamily: '"HelveticaNeue", sans-serif',
                fontSize: 18,
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '2.81',
                letterSpacing: 'normal',
                color: '#34475b',
                '& strong': {
                  fontFamily: '"HelveticaNeueLTStd-Bd", sans-serif'
                },
                '@media(max-width: 960px)': {
                  fontSize: 15
                }
              }))}>
                {child.render}
              </div>
            </Collapsible>
          </div>
        )
      })}
    </div>
  );
};

export default GeneralInfoBox;


export interface IGeneralInfoTableProps {
  className?: string;
}

export const GeneralInfoTable: React.FC<IGeneralInfoTableProps> = props => {
  return (
    <table className={cx(css({
      borderCollapse: 'collapse',
      '& tr:not(:last-child)': {
        borderBottom: 'solid 0.5px #34475b',
        'td:first-child': {
          fontWeight: 500
        }
      },
      width: '100%',
      marginBottom: 10
    }), props.className)}>
      {props.children}
    </table>
  );
};
