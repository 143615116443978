import { OrderId } from "../../type/shared";
import { useGetRequest } from "../api";
import { methods, orderApiPath, IApiOrderResponse, IWithRelatedOffer } from "../../lib/api";

type OrderApiResponse = {
  order: IApiOrderResponse & IWithRelatedOffer
}

export const useOrder = (orderId: OrderId) => {
  return useGetRequest<OrderApiResponse>(orderApiPath(orderId), methods.get)
}
