import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { ProtectedRoute } from "../lib/shared";
import ReactIframeResizer from 'react-iframe-resizer-super';

type UpdatesPageContainerProps = RouteComponentProps<{}>

export const UpdatesPageContainer: React.FC<UpdatesPageContainerProps> = (props) => {
  window.scrollTo(0, 0)

  return (
    <ProtectedRoute>
      <Container>
        <ReactIframeResizer
          className={"newsIframe"}
          iframeResizerOptions={{checkOrigin: false}}
          iframeResizerUrl={false}
          src="https://www.vestaboard.com/customer-updates/"
        />
      </Container>
    </ProtectedRoute>
  )
}
