import React, { Component } from 'react';
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import './App.css';
import "tachyons";

import { AppContainer } from './container/app-container';

const bugSnagKey = process.env.REACT_APP_BUGSNAG_KEY
export const bugsnagClient = bugsnag(bugSnagKey || "")
bugsnagClient.use(bugsnagReact, React)

const ErrorBoundary = bugsnagClient.getPlugin('react')

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <AppContainer />
      </ErrorBoundary>
    );
  }
}

export default App;
