export type LandingPageId = string;

export type OfferId = string;

export type CompanyId = string;

export type ProductId = string;

export type CustomerId = string;

export type OrderId = string;

export enum ECurrency {
  USD,
  GBP
}

interface IMonetaryValue {
  currency: ECurrency,
  amountInSmallestCurrency: number
}

interface IBalanceChange {
  timestamp: number;
  balance: number;
}

interface IOffer {
  id: OfferId;
  retailPrice: number;
  discountedPrice?: number;
  depositPrice?: number;
  productName?: string;
}

interface IOrder {
  quantity: number;
  offer: IOffer;
  balanceChanges: IBalanceChange[];
  customer: ICustomer;
}

interface ICustomer {
  firstName: string;
  lastName: string;
  emailAddress: string;
  companies: ICompany[];
}

export type Customer = ICustomer
export type Company = ICompany
export type ShippingGroup = string

interface IShippingAddress {

}

export type ShippingAddress = IShippingAddress

interface ICompany {
  name: string;
}

export enum ELoadingState {
  Loading,
  NotLoading
}
