import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";

export const Loading = () => (
  <div id="spinner" style={{ justifyContent: "center" }}>
    <div className="d-flex justify-content-center">
      <div className="spin spinner-border text-dark" style={{ width: "3rem", height: "3rem" }} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  </div>
)
