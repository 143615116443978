import React from 'react';
import Collapsible from "../collapsible";
import { css, cx } from "emotion";
import { FiCheck } from 'react-icons/fi';


export interface IStepperIndicatorProps {
  num: number;
  state: 'active' | 'complete' | 'incomplete';
  className?: string;
}

const StepperIndicator: React.FC<IStepperIndicatorProps> = props => {
  return (
    <div className={cx("dib", props.className)}>
      <div className={cx("flex justify-center items-center", css({
        width: '44px',
        height: '44px',
        border: props.state !== 'complete' ? 'solid 2px #34475b' : 'solid 2px #1f9a44',
        backgroundColor: props.state === 'complete' ? '#1f9a44' : 'transparent',
        borderRadius: '50%',
        '@media(max-width: 960px)': {
          width: '34px',
          height: '34px',
          borderRadius: '50%'
        }
      }))}>
        <div className={cx("flex justify-center items-center", css({
          width: '36px',
          height: '36px',
          backgroundColor: props.state === 'active' ? '#2083d5' : 'transparent',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media(max-width: 960px)': {
            width: '28px',
            height: '28px',
            borderRadius: '50%'
          }
        }))}>
          <div className={css({
            fontFamily: '"HelveticaNeueLTStd-Bd", sans-serif',
            fontSize: '23px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.27px',
            color: props.state !== 'incomplete' ? '#ffffff' : '#25282a',
            textAlign: 'center',
            '@media(max-width: 960px)': {
              fontSize: '18px'
            }
          })}>
            {props.state === 'complete' ? (
              <FiCheck />
            ) : props.num}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface ISeparatorLineProps {
  className?: string;
}

const SeparatorLine: React.FC<ISeparatorLineProps> = props => {
  return (
    <div className={cx("dib", props.className)}>
      <div className={cx("flex justify-center items-center", css({
        width: '46px',
        height: '46px',
        '@media(max-width: 960px)': {
          width: '34px',
          height: '34px',
        }
      }))}>
        <div className={css({
          width: 1,
          height: 24,
          border: 'solid 1px #34475b'
        })} />
      </div>
    </div>
  );
};

export interface IVerticalStepperProps {
  children: Array<{
    heading: React.ReactChild;
    render: React.ReactChild;
    key: string;
  }>;
  className?: string;
  currentState: string | null;
  debugMode?: boolean;
}

const VerticalStepper: React.FC<IVerticalStepperProps> = props => {
  const allStates = props.children.map(s => s.key)

  const currentStateIndex = props.currentState ? allStates.indexOf(props.currentState) : -1

  return (
    <div className={props.className}>
      {props.children.map((child, i, arr) => {
        return (
          <React.Fragment key={i}>
            <Collapsible
              isOpen={(i === currentStateIndex) || props.debugMode}
              disabled={true}
              heading={
                <div className={cx("flex items-center", css({
                  justifyContent: 'space-between',
                  height: 50
                }))}>
                  <StepperIndicator state={i < currentStateIndex ? 'complete' : i === currentStateIndex ? 'active' : "incomplete"} num={i + 1} className={cx("v-mid", css({
                    marginRight: 42,
                    '@media(max-width: 960px)': {
                      marginRight: 24
                    }
                  }))} />
                  {child.heading}
                </div>
              }>
              <div className={cx(css({
                position: 'relative',
                paddingTop: 14,
                paddingLeft: 62,
                marginLeft: 22,
                fontFamily: '"HelveticaNeueLTStd-Roman", sans-serif',
                fontSize: 18,
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.78',
                width: "calc(100% - 82px)",
                letterSpacing: '0.21px',
                color: '#34475b',
                '& strong': {
                  fontFamily: '"HelveticaNeueLTStd-Bd", sans-serif'
                },
                '@media(max-width: 960px)': {
                  marginLeft: 16,
                  paddingLeft: 42,
                  width: "calc(100% - 28px)",
                  fontSize: 16,
                  p: {
                    paddingTop: 7
                  }
                }
              }))}>
                <div className={cx(css({
                  position: 'absolute',
                  backgroundColor: i !== arr.length - 1 ? '#34475b' : 'transparent',
                  width: 2,
                  height: '100%',
                  left: '0px',
                  top: '14px',
                  '@media(max-width: 960px)': {
                    top: '6px'
                  }
                }))} />
                {child.render}
              </div>
            </Collapsible>
            {
              i !== arr.length - 1 && (
                <SeparatorLine />
              )
            }
          </React.Fragment >
        )
      })}
    </div >
  );
};

export default VerticalStepper;
