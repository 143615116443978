import * as React from "react";

import { Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap"

import Button from "react-bootstrap/Button";
import history from "../../history";
import logo from "../../logo.png"
import { useAuth0 } from "@auth0/auth0-react";

type HeaderProps = {}

const headerStyle = {
  background: "#25282a",
  boxShadow: "0px 1px 7px grey"
}

const brandStyle = {
  marginLeft: '-15px'
}

export const Header: React.SFC<HeaderProps> = () => {
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false)
  const { user, isAuthenticated, logout } = useAuth0();

  const navigate = (path: string) => {
    history.push(path);
    setMobileNavOpen(false);
  }

  return (
    <Navbar expanded={mobileNavOpen} sticky={"top"} collapseOnSelect expand="md" variant="dark" style={headerStyle}>
      <Container>
        <Navbar.Brand style={brandStyle} href="https://www.vestaboard.com">
          <img
            alt="Vestaboard"
            src={logo}
            height="47"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        {isAuthenticated && (
          <>
            <Navbar.Toggle onClick={() => setMobileNavOpen(!mobileNavOpen)} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse>
              <Nav className="mr-auto">
                <Nav.Link onClick={() => navigate("/")}>Orders</Nav.Link>
                <Nav.Link onClick={() => { window.location.href = 'https://vestaboard.com/news' }}>Updates</Nav.Link>
                <Nav.Link onClick={() => navigate("/preferences")}>Preferences</Nav.Link>
                <Nav.Link onClick={() => { window.location.href = 'https://vestaboard.com/help' }}>Help Center</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link href="#">
                  <Button onClick={() => logout({ returnTo: window.location.origin })} className="logout flex-shrink-1" variant="secondary">Logout</Button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  )
}
