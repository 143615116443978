import { CustomerId, ProductId, OrderId, OfferId, CompanyId } from "../type/shared";

export const customerPath = (customerId: CustomerId) => `/customer`
export const productPath = (productId: ProductId) => `/product/${productId}`
export const getCustomerPath = (customerId: CustomerId) => `/customer`
export const createOrderPath = () => `/order`
export const orderApiPath = (orderId: OrderId) => `/order/${orderId}`
export const offerApiPath = (offerId: OfferId) => `/offer/${offerId}`

export interface IApiCustomerResponse {
  id: CustomerId;
  firstName: string;
  lastName: string;
  emailAddress: string;
  totalBalance: number;
  interestedInApi: boolean;
  interestedInBetaTesting: boolean;
  interestedInTrade: boolean;
}

export interface IApiCompanyResponse {
  id: CompanyId;
  createdAt: number;
  name: string;
  domainName?: string;
}

export interface IApiOfferResponse {
  id: OfferId;
  retailPrice: number;
  discountedPrice?: number;
  depositPrice?: number;
  currency: string;
  productName: string;
}

export interface IApiBalanceChange {
  createdAt: number;
  balance: number;
  currency: string;
}

export interface OrderLineItemCharge {
  description: string;
  balance: number | null;
}

export const orderedOrderStatuses: OrderStatus[] = [
  "ConfirmYourShippingAddress",
  "YourFinalBalanceIsBeingPrepared",
  "ConfirmYourOrderByPayingFinalBalance",
  "YourOrderIsConfirmed",
  "YourVestaboardIsBeingMade",
  "YourVestaboardHasShipped"
]

export type OrderStatus =
  | "ConfirmYourShippingAddress"
  | "YourFinalBalanceIsBeingPrepared"
  | "ConfirmYourOrderByPayingFinalBalance"
  | "YourOrderIsConfirmed"
  | "YourVestaboardIsBeingMade"
  | "YourVestaboardHasShipped"

export interface IApiOrderResponse {
  id: OrderId;
  createdAt: number;
  quantity: number;
  currentBalance: number;
  currentPaidAmount: number;
  friendlyIdentifier: string;
  shippingStreetAddress: string | null;
  shippingCity: string | null;
  shippingStateProvince: string | null;
  shippingCountry: string | null;
  shippingPostalCode: string | null;
  shippingCompany: string | null;
  shippingContact: string | null;
  estimatedDelivery: string | null;
  shipmentLetter: string | null;
  lineItemCharges: OrderLineItemCharge[];
  taxesBalance: number | null;
  dutiesBalance: number | null;
  shippingBalance: number | null;
  orderStatus: OrderStatus | null;
  trackingLink: string | null;
  phone: string | null;
  dutyPaidTiming: string | null;
  dapConfirmation: boolean | null;
}

export interface IWithRelatedOrders {
  orders: IApiOrderResponse[];
}

export interface IWithRelatedOrdersAndOffers {
  orders: (IApiOrderResponse & IWithRelatedOffer)[]
}

export interface IWithRelatedOffer {
  offer?: IApiOfferResponse;
}

export interface IWithRelatedCompanies {
  companies: IApiCompanyResponse[];
}

export const methods = {
  get: "GET",
  post: "POST",
  put: "PUT"
}
