import { useEffect, useState } from "react";
import { ELoadingState } from "../type/shared";
import { getEnv } from "../lib/env";
import { logoutUser, getAccessToken } from "../lib/shared";
import { useAuth0 } from "@auth0/auth0-react";

const apiBaseUrl = getEnv("api_base_url")

export interface IApiResponseHook<T> {
  loading: ELoadingState;
  error?: string | null;
  data: T | null;
  refetch?: () => any;
}

interface IPostApiResponseHook<T> extends IApiResponseHook<T> {
  doPost: (body: any) => Promise<void>
}

interface IPatchApiHook<T> extends IApiResponseHook<T> {
  doPatch: (body: any) => Promise<void>
}

export const usePostRequest = <T extends any, B extends any = {}>(path: string): IPostApiResponseHook<T> => {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<ELoadingState>(ELoadingState.Loading)

  const doPost = async (body: any = {}) => {
    setLoading(ELoadingState.Loading)

    try {
      const response = await fetch(`${apiBaseUrl}${path}`, {
        method: "post",
        body: JSON.stringify(body)
      })

      const json = await response.json()

      setError(null)
      setData(json)
      setLoading(ELoadingState.NotLoading)
    } catch (error) {
      console.log(error)
      setError(error)
      setLoading(ELoadingState.NotLoading)
      throw error
    }
  }

  return {
    data,
    error,
    loading,
    doPost
  }
}

export const usePatchRequest = <T extends any, B extends any = {}>(path: string): IPatchApiHook<T> => {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<ELoadingState>(ELoadingState.Loading)
  const { user } = useAuth0()

  const headers = {
    "X-Vestaboard-Session-Token": JSON.stringify(user)
  }

  const doPatch = async (body: any = {}) => {
    setLoading(ELoadingState.Loading)

    try {
      const response = await fetch(`${apiBaseUrl}${path}`, {
        method: "put",
        body: JSON.stringify(body),
        headers
      })

      const json = await response.json()

      setError(null)
      setData(json)
      setLoading(ELoadingState.NotLoading)
    } catch (error) {
      console.log(error)
      setError(error)
      setLoading(ELoadingState.NotLoading)
      throw error
    }
  }

  return {
    data,
    error,
    loading,
    doPatch
  }
}

export const useGetRequest = <T extends any, B extends any = {}>(
  path: string,
  method: string,
  accessToken?: string,
  body?: B,
): IApiResponseHook<T> => {
  const { user } = useAuth0()

  const headers = {
    "X-Vestaboard-Session-Token": JSON.stringify(user)
  }

  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<ELoadingState>(ELoadingState.Loading)
  const [fetched, setFetched] = useState(false)

  const doFetch = async () => {
    setFetched(true)
    setLoading(ELoadingState.Loading)
    try {
      const response = await fetch(`${/*I path.includes('customer') || path.includes('order') ? 'http://localhost:9000' : */ apiBaseUrl}${path}`, { headers })
      if (response.status === 401 || response.status === 403 || response.status === 404) {
        logoutUser()
      }
      const json = await response.json()
      setData(json)
      setLoading(ELoadingState.NotLoading)
    } catch (error) {
      setError(error)
      setLoading(ELoadingState.NotLoading)
    }
  }

  const refetch = () => {
    doFetch()
  }

  useEffect(() => {
    doFetch()
  }, [path])

  return {
    data,
    error,
    loading,
    refetch
  }
}
