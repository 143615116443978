import * as React from "react"
import { Container, Row, Col, Badge } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { ProtectedRoute } from "../lib/shared";
import ReactIframeResizer from "react-iframe-resizer-super";

type ArticleProps = RouteComponentProps<{ slug: string }>

export const ArticleContainer: React.SFC<ArticleProps> = (props) => {
  return (
    <ProtectedRoute>
      <Container>
        <ReactIframeResizer
          className={"newsIframe"}
          iframeResizerOptions={{checkOrigin: false}}
          iframeResizerUrl={false}
          src={`https://www.vestaboard.com/customer-updates/${props.match.params.slug}`}
        />
      </Container>
    </ProtectedRoute>
  )
}
