import * as React from "react"
import { useState, useEffect } from "react"
import { oc } from "ts-optchain";
import { getEnv } from "./env";
import { Container, Row, Col, Jumbotron, Button } from "react-bootstrap";
import history from "../history";
import jwtDecode from "jwt-decode"
import queryString from "query-string";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "../component/atom/loading";

export const safe = oc

export type IWithAccessToken = { accessToken: string }

type ProtectedRouteProps = {
  children: React.ReactElement
}

export const logoutUser = () => {
  history.push("/logout")
}

export const analytics = {
  track: (event: string | string[], properties: any = {}) => {
    console.log("track", event, properties)
    return new Promise(
      (resolve) => {
        if (Array.isArray(event)) {
          console.log("event", event)
          event.map(
            (e) => {
              (window as any).analytics.track(e, properties, {}, resolve)
            }
          )
        } else {
          (window as any).analytics.track(event, properties, {}, resolve)
        }
      }
    )
  },
  identify: (userId: string, traits: any = {}) => {
    return new Promise(
      (resolve) => {
        (window as any).analytics.identify(userId, traits, {}, resolve)
      }
    )
  }
}

export const AnalyticsEvents = {
  events: {
    invalidLink: 'invalid.link',
    logout: 'logout',
    auth0: {
      parseHash: {
        started: 'auth0.parse_hash.started',
        success: 'auth0.parse_hash.success',
        failure: 'auth0.parse_hash.failure',
        done: 'auth0.parse_hash.done'
      },
      sessionRenewal: {
        started: 'auth0.session_renewal.started',
        success: 'auth0.session_renewal.success',
        failure: 'auth0.session_renewal.failure',
        done: 'auth0.parse_hash.done'
      }
    },
    stripe: {
      source: {
        success: 'stripe.source.success',
        failure: 'stripe.source.failure'
      }
    },
    payment: {
      submitted: 'payment.submitted',
      success: 'payment.success',
      failure: 'payment.failure'
    }
  },
  pages: {
    login: {
      load: 'pages.login.load',
      clickEmailAddress: 'pages.login.click_email_address',
      typeEmailAddress: 'pages.login.type_email_address',
      emailButton: 'pages.login.email_button'
    },
    customer: {
      load: 'pages.customer.load',
      totalBalanceCTA: 'pages.customer.total_balance_cta',
      orderDetailCTA: 'pages.customer.order_detail_cta'
    },
    orderDetail: {
      load: 'pages.order_detail.load',
      balanceCTA: 'pages.order_detail.balance_cta',
      shipping: 'pages.order_detail.shipping_cta'
    },
    checkout: {
      clickedEmailAddressField: ['pages.checkout.email_address_field.clicked', 'pages.checkout.identified'],
      clickedFirstNameField: ['pages.checkout.first_name_field.clicked', 'pages.checkout.identified'],
      clickedLastNameField: ['pages.checkout.last_name_field.clicked', 'pages.checkout.identified'],
      selectedCreditCard: ['pages.checkout.credit_card.clicked', 'pages.checkout.payment_method'],
      selectedApplePay: ['pages.checkout.apple_pay.clicked', 'pages.checkout.payment_method'],
      selectedPayPal: ['pages.checkout.paypal.clicked', 'pages.checkout.payment_method'],
      clickedQuantityField: 'pages.checkout.quantity.clicked',
      creditCardNumberClicked: ['pages.checkout.credit_card_number.clicked', 'pages.checkout.credit_card'],
      cvcClicked: ['pages.checkout.cvc.clicked', 'pages.checkout.credit_card'],
      expirationDateClicked: ['pages.checkout.expiration_date.clicked', 'pages.checkout.credit_card'],
      postalCodeClicked: ['pages.checkout.postalcode.clicked', 'pages.checkout.credit_card']
    },
    balanceCheckout: {
      load: 'pages.checkout.balance.load',
      payButton: 'pages.checkout.balance.pay_button'
    },
    offerCheckout: {
      load: 'pages.checkout.offer.load',
      applePay: 'pages.checkout.offer.apple_pay_selected',
      creditCard: 'pages.checkout.offer.apple_pay_selected',
      payPal: 'pages.checkout.offer.paypal_selected',
      payButton: 'pages.checkout.offer.pay_button',
      adjustQuantity: 'pages.checkout.offer.quantity_adjusted'
    }
  }
}

const apiBaseUrl = getEnv("api_base_url")
const ACCESS_TOKEN = "vestaboardAccessToken"

export const logout = () => {
  destroyAccessToken()
  history.replace("/login")
}

const destroyAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN)
}

const persistAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token)
}

const handleError = () => {
  destroyAccessToken()
  history.push("/login")
}

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN)
}

export const SignUp: React.FC = (props) => {
  const { loginWithRedirect } = useAuth0()

  React.useEffect(() => {
    loginWithRedirect({ action: "signup" })

  }, [])
  return <span />
}

export const ProtectedRoute: React.SFC<ProtectedRouteProps> = (props) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(
    () => {
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect()
      }
    }, [isAuthenticated, isLoading]
  )

  if (!isLoading && isAuthenticated) {
    return props.children
  } else {
    return <Loading />
  }
}

export const Tracker = (props: { name: string, children: React.ReactChild }) => {
  analytics.track(name)

  return props.children
}
