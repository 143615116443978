import { useState } from "react";

interface IAppState {
  error: string | null;
  setError: (v: string) => void
}

export type AppState = IAppState

export const useAppContainer = (): AppState => {
  const [error, setError] = useState<string | null>(null)

  return {
    error,
    setError
  }
}
