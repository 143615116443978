import * as React from "react";
import { useEffect, useState } from "react";
import { Checkout } from "../component/page/checkout-page";
import { ELoadingState, OfferId } from "../type/shared";
import { useGetRequest, usePostRequest } from "../hook/api";
import { IApiCustomerResponse, IWithRelatedCompanies, IWithRelatedOrdersAndOffers, methods } from "../lib/api";
import { useCustomer } from "../hook/data/customer";
import { Elements, injectStripe, ReactStripeElements } from "react-stripe-elements";
import queryString from "query-string"
import { some } from "lodash"
import { analytics, AnalyticsEvents, ProtectedRoute } from "../lib/shared";

type CheckoutContainerOwnProps = {}

type CheckoutContainerProps = CheckoutContainerOwnProps

export enum ECheckoutScreen {
  ContactInformation,
  PaymentMethodSelection,
  PaymentInformation,
  ThankYou
}

export enum EPaymentMethod {
  Stripe,
  CreditCard,
  ApplePay,
  PayPal
}

enum EMode {
  Collection,
  Confirmation
}

enum ECartDataType {
  Offer,
  OrderPayment
}

interface IOfferCartData {
  offerId: string;
  type: ECartDataType.Offer;
}

interface IOrderPaymentCartData {
  orderIds: string[];
  type: ECartDataType.OrderPayment;
}

type CartData = IOfferCartData | IOrderPaymentCartData

interface ICartItem {
  name: string;
  quantity: number;
  unitPrice: number;
  subtotal: number;
  quantityEditable: boolean;
  key: string;
  offerId?: string;
  orderId?: string;
  minimumQuantity?: number | null;
}

export type CartItem = ICartItem;

type CartQuantities = { [key: string]: number }

const defaultOfferId = "52B046B3-F6A9-411F-9881-07CCE935E38F"

export interface IFormValues {
  firstName: string;
  lastName: string;
  emailAddress: string;
  applePayToken?: string | null;
}

export enum EPaymentScheme {
  Now,
  Installments
}

const payPalButtonIds: { [key: string]: string } = {
  [defaultOfferId.toLowerCase()]: "28X5HVZ6QBJFG"
}

interface IPayment {
  date: number;
  amount: number;
}

export interface IHypotheticalInstallmentPlan {
  payments: IPayment[]
}

export const useCheckoutContainer = (
  cartData: CartData,
  customer: (IApiCustomerResponse & IWithRelatedCompanies & IWithRelatedOrdersAndOffers) | null
) => {
  const stripeElementsEmptyStates = {
    cardNumber: true,
    cardCvc: true,
    cardExpiry: true,
    postalCode: true
  }

  const [screen, setScreen] = useState(ECheckoutScreen.ContactInformation);
  const [cartItems, setCartItems] = useState<CartItem[]>([])
  const [paymentMethod, setPaymentMethod] = useState<EPaymentMethod | null>(null)
  const [companyName, setCompanyName] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [mode, setMode] = useState(EMode.Collection)
  const [showBack, setShowBack] = useState(true)
  const [quantities, setQuantities] = useState<CartQuantities>({})
  const [customerInfoEnabled, setCustomerInfoEnabled] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [elementFieldEmpty, setElementFieldEmpty] = useState(stripeElementsEmptyStates)
  const [formError, setFormError] = useState('')
  const [applePayEnabled, setApplePayEnabled] = useState(false)

  const setElementFieldIsEmpty = (field: string, empty: boolean) => {
    setElementFieldEmpty({
      ...elementFieldEmpty,
      [field]: empty
    })
  }

  const setQuantity = (offerId: OfferId, quantity: number, minQuantity?: number) => {
    if (!minQuantity || (quantity >= minQuantity)) {
      setQuantities({
        ...quantities,
        [offerId]: quantity
      })
    }
  }

  const applePayShown = applePayEnabled && !some(Object.keys(quantities).map(key => quantities[key] !== 1))

  const stripeElementsFieldsAllFilled =
    !elementFieldEmpty.cardCvc &&
    !elementFieldEmpty.cardExpiry &&
    !elementFieldEmpty.cardNumber &&
    !elementFieldEmpty.postalCode

  const offerId = (cartData.type === ECartDataType.Offer && cartData.offerId) || defaultOfferId

  const [paymentScheme, setPaymentScheme] = React.useState(EPaymentScheme.Now)
  const [numberOfInstallments, setNumberOfInstallments] = React.useState(2)

  const installmentPlan = usePostRequest<IHypotheticalInstallmentPlan>("/installment-plan")

  useEffect(
    () => {
      if (cartItems[0] && cartItems[0].orderId) {
        installmentPlan.doPost({
          orderId: cartItems[0].orderId,
          numberOfInstallments
        })
      }
    }, [cartItems, numberOfInstallments]
  )

  const getCartPaymentPostBody = () => {
    if (cartItems.length === 0) {
      return {}
    }

    if (cartItems[0].offerId) {
      const {
        offerId,
        quantity
      } = cartItems[0]

      return {
        offerId,
        quantity,
        totalAmountShownInCents: cartTotal
      }
    } else {
      if (!customer) throw Error("unexpected - customer not provided")

      const orderIds = cartItems
        .map((item) => item.orderId)

      orderIds.forEach((item) => {
        if (!item) {
          throw Error("unexpected - cart order id item is null")
        }
      })

      const installmentPlanPaymentAmount = (installmentPlan && installmentPlan.data && installmentPlan.data.payments[0] && installmentPlan.data.payments[0].amount)

      const payNowAmount = paymentScheme === EPaymentScheme.Now ? cartTotal : installmentPlanPaymentAmount

      if (!payNowAmount) throw Error("unexpected - installment plan must be available if payment scheme is not 'NOW'")

      const installmentPlanRequest = (paymentScheme === EPaymentScheme.Now) ? null : {
        installmentPlanPaymentCount: numberOfInstallments,
        installmentPlanPaymentAmount
      }

      return {
        installmentPlan: installmentPlanRequest,
        orderIds: orderIds as string[],
        customerId: customer.id,
        totalAmountShownInCents: cartTotal
      }
    }
  }

  const [payPalButtonId, setPayPalButtonId] = useState("")

  useEffect(
    () => {
      setPayPalButtonId("")
      if (cartItems.length === 1 && cartData.type === ECartDataType.Offer) {
        setPayPalButtonId(payPalButtonIds[cartItems[0].offerId as string])
      }
    },
    [cartItems, cartData]
  )

  const showPaymentOptions = true

  const {
    doPost: postPaymentRequest,
    error: postPaymentError
  } = usePostRequest("/payment")

  const getQuantity = (key: string) => quantities[key] || 1

  const completeApplePayTransaction = async (
    token: string,
    firstName: string,
    lastName: string,
    emailAddress: string
  ) => {
    setSubmitting(true)

    await analytics.track(AnalyticsEvents.events.payment.submitted)

    try {
      await postPaymentRequest({
        ...getCartPaymentPostBody(),
        firstName,
        lastName,
        emailAddress,
        stripeSource: token
      })
      await analytics.identify(
        emailAddress,
        {
          email: emailAddress,
          firstName,
          lastName
        }
      )
      await analytics.track(AnalyticsEvents.events.payment.success)
      setFormError("")
      window.location.href = "https://vestaboard.com/thank-you"
    } catch (error) {
      await analytics.identify(
        emailAddress,
        {
          email: emailAddress,
          firstName,
          lastName
        }
      )
      await analytics.track(AnalyticsEvents.events.payment.failure)
      setSubmitting(false)
      setFormError("Your charge could not be processed.")
    }
  }

  const submit = async (
    values: IFormValues,
    stripe: ReactStripeElements.StripeProps
  ) => {
    setSubmitting(true)

    let stripeSource = null;
    if (values.applePayToken) {
      stripeSource = values.applePayToken
    } else {
      const source = await stripe.createSource({ type: 'card' })

      console.log("stripe source", source)
      if (!(source && source.source) || source.error) {
        setSubmitting(false)
        setFormError("Your charge could not be processed.")
        return
      }

      stripeSource = source.source.id
    }

    try {
      await postPaymentRequest({
        ...getCartPaymentPostBody(),
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
        stripeSource
      })
      analytics.identify(
        values.emailAddress,
        {
          email: values.emailAddress,
          firstName: values.firstName,
          lastName: values.lastName
        }
      )
      analytics.track(AnalyticsEvents.events.payment.success)
      setFormError("")
      window.location.href = "https://vestaboard.com/thank-you"
    } catch (error) {
      analytics.identify(
        values.emailAddress,
        {
          email: values.emailAddress,
          firstName: values.firstName,
          lastName: values.lastName
        }
      )
      analytics.track(AnalyticsEvents.events.payment.failure)
      setSubmitting(false)
      setFormError("Your charge could not be processed.")
    }
  }

  useEffect(
    () => {
      if (customer && cartData.type === ECartDataType.OrderPayment) {
        const validOrders = customer
          .orders
          .filter(order => cartData.orderIds.includes(order.id))
          .filter(order => order.currentBalance > 0)

        const balancePaymentCartItems = validOrders.flatMap(order => order.lineItemCharges.map(lineItem => ({
          name: lineItem.description,
          unitPrice: lineItem.balance || 0,
          quantity: 1,
          subtotal: lineItem.balance || 0,
          quantityEditable: false,
          key: `${order.id}_${lineItem.description}`,
          orderId: order.id
        })))

        setFirstName(customer.firstName)
        setLastName(customer.lastName)
        setEmailAddress(customer.emailAddress)
        setCustomerInfoEnabled(false)
        setCartItems(balancePaymentCartItems)
      }
    },
    [customer, quantities]
  )

  const cartTotal = cartItems.reduce(
    (acc, item) => acc + (item.quantity * item.unitPrice), 0
  )

  const loading = ELoadingState.NotLoading

  const finePrint = ""

  const hypotheticalInstallmentPlan = installmentPlan.data

  const installmentPlanLoading = installmentPlan.loading === ELoadingState.Loading

  const creditCardEnabled = true

  return {
    paymentMethod,
    companyName,
    loading,
    mode,
    finePrint,
    showBack,
    applePayEnabled,
    submit,
    cartItems,
    cartTotal,
    setQuantity,
    customerInfoEnabled,
    firstName,
    lastName,
    emailAddress,
    setElementFieldIsEmpty,
    stripeElementsFieldsAllFilled,
    formError,
    submitting,
    setPaymentMethod,
    payPalButtonId,
    applePayShown,
    setApplePayEnabled,
    showPaymentOptions,
    completeApplePayTransaction,
    hypotheticalInstallmentPlan,
    paymentScheme,
    setPaymentScheme,
    numberOfInstallments,
    setNumberOfInstallments,
    installmentPlanLoading,
    screen,
    setScreen,
    creditCardEnabled
  }
}

export const InjectedCheckoutPage = injectStripe(Checkout)

const useQueryString = () => {
  const [queryParameters, setQueryParameters] = useState<any>({})
  useEffect(
    () => {
      const qs = queryString.parse(location.search)
      setQueryParameters(qs)
    }, []
  )
  return queryParameters
}

export const CheckoutContainer: React.SFC<CheckoutContainerProps> = (props) => {
  const queryParameters = queryString.parse(window.location.search)

  let cartData: CartData | null = null
  if (queryParameters.offerId) {
    cartData = {
      type: ECartDataType.Offer,
      offerId: queryParameters.offerId as string
    }
  } else if (queryParameters.orderIds) {
    cartData = {
      type: ECartDataType.OrderPayment,
      orderIds: queryParameters.orderIds.split(",") as string[]
    }
  } else {
    cartData = {
      type: ECartDataType.Offer,
      offerId: defaultOfferId
    }
  }

  if (!cartData) return <span />

  switch (cartData.type) {
    case ECartDataType.Offer:
      analytics.track(AnalyticsEvents.pages.offerCheckout.load)

      return (
        <OfferCheckoutContainer cartData={cartData} />
      )
    case ECartDataType.OrderPayment:
      analytics.track(AnalyticsEvents.pages.balanceCheckout.load)

      return (
        <ProtectedRoute>
          <OrderPaymentCheckoutContainer
            cartData={cartData as any}
          />
        </ProtectedRoute>
      )
    default:
      return <span />
  }
}

export const OfferCheckoutContainer = (props: {
  cartData: CartData
}) => {
  const state = useCheckoutContainer(props.cartData, null)
  return (
    <Elements>
      <InjectedCheckoutPage {...state} />
    </Elements>
  )
}

export const OrderPaymentCheckoutContainer = (props: {
  cartData: CartData
}) => {
  const {
    data: customer,
    loading: customerLoading
  } = useCustomer("me", "")

  const state = useCheckoutContainer(props.cartData as any, customer)

  const loading = (state.loading === ELoadingState.NotLoading) && (customerLoading === ELoadingState.NotLoading) ?
    ELoadingState.NotLoading :
    ELoadingState.Loading

  return (
    <Elements>
      <InjectedCheckoutPage {...state} loading={loading} />
    </Elements>
  )
}
