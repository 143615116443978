import * as React from "react";
import { useState, useEffect } from "react"
import { ReactStripeElements, PaymentRequestButtonElement } from "react-stripe-elements";

interface IApplePayContainerProps {
  submitting: boolean;
  firstName: string;
  lastName: string;
  emailAddress: string;
  shown: boolean;
  stripe: ReactStripeElements.StripeProps,
  label: string;
  amount: number;
  completeApplePayTransaction: (token: string) => void;
  setApplePayEnabled: (enabled: boolean) => void;
  setApplePayFailure: (failed: boolean) => void;
}

type ApplePayContainerProps = IApplePayContainerProps

const UntypedPaymentRequestButtonElement = PaymentRequestButtonElement as any;

const paymentRequestButtonStyles = {
  height: "40px"
}

export const useApplePay = (params: IApplePayContainerProps) => {
  const parentSetApplePayEnabled = params.setApplePayEnabled
  const [applePayEnabled, setApplePayEnabled] = useState(false)
  const [paymentRequestButtons, setPaymentRequestButtons] = useState<any>({})

  const setPaymentRequestButton = (key: any, button: any) =>
    setPaymentRequestButtons({
      ...paymentRequestButtons,
      [key]: button
    })

  const getPaymentRequestButton = (key: any) =>
    paymentRequestButtons[key] || <span />

  const {
    stripe,
    label,
    amount,
    completeApplePayTransaction,
    setApplePayFailure
  } = params

  const COUNTRY = "US"
  const CURRENCY = "usd"

  useEffect(
    () => parentSetApplePayEnabled(applePayEnabled),
    [applePayEnabled]
  )

  useEffect(
    () => {
      const pr = stripe.paymentRequest({
        country: COUNTRY,
        currency: CURRENCY,
        total: {
          label,
          amount
        },
        requestPayerName: false,
        requestPayerEmail: false,
      })

      pr.on('token', async (action: any) => {
        const { complete, token } = action;

        try {
          await completeApplePayTransaction(token.id)
          complete('success');
        } catch (error) {
          setApplePayFailure(true)
          complete('fail');
        }
      });

      const paymentRequestProps = {
        paymentRequest: pr,
        className: "apple-pay",
        style: { paymentRequestButton: paymentRequestButtonStyles }
      }

      pr.canMakePayment().then((result: any) => {
        if (!!result) {
          setApplePayEnabled(true)
          setPaymentRequestButton(
            amount,
            (<UntypedPaymentRequestButtonElement {...paymentRequestProps} />)
          )
        }
      });
    },
    [amount]
  )

  return {
    button: getPaymentRequestButton(amount),
    applePayEnabled
  }
}

export const PaymentRequestButton: React.SFC<IApplePayContainerProps> = (props) => {
  const applePay = useApplePay(props)

  return (
    <div className="applePayContainer">
      {props.shown && applePay.button}
    </div>
  )
}
