import { useGetRequest } from "../api"
import { methods, getCustomerPath, IApiCustomerResponse, IWithRelatedCompanies, IWithRelatedOrdersAndOffers } from "../../lib/api";
import { CustomerId } from "../../type/shared";
import { analytics } from "../../lib/shared";

type CustomerApiResponse = IApiCustomerResponse & IWithRelatedCompanies & IWithRelatedOrdersAndOffers

export const useCustomer = (customerId: CustomerId, accessToken: string) => {
  const response = useGetRequest<CustomerApiResponse, {}>(
    getCustomerPath(customerId),
    methods.get,
    accessToken
  )

  if (response.data) {
    const name = (response.data.firstName && response.data.lastName) ? `${response.data.firstName} ${response.data.lastName}` : undefined

    analytics.identify(
      response.data.emailAddress,
      {
        email: response.data.emailAddress,
        name
      }
    )
  }

  return response
}
