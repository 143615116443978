import * as React from "react";
import {
  CartItem,
  EPaymentMethod,
  EPaymentScheme,
  IFormValues as ICheckoutFormValues,
  IFormValues,
  IHypotheticalInstallmentPlan,
  ECheckoutScreen
} from "../../container/checkout-page";
import { formatMonetaryValue, formatTimestamp } from "../../lib/format";
import { ELoadingState } from "../../type/shared";
import { Formik, FormikActions } from "formik"
import * as yup from "yup"
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  PostalCodeElement,
  ReactStripeElements
} from "react-stripe-elements"
import { Loading } from "../atom/loading";
import { PaymentRequestButton } from "../../container/apple-pay-container";
import { analytics, AnalyticsEvents } from "../../lib/shared";
import CartHeader from "../molecule/cart-header";

const buyLogoImage = require("../../image/buy-logo-large.png");

export interface ICheckoutProps {
  cartItems: CartItem[]
  cartTotal: number
  loading: ELoadingState
  firstName: string
  lastName: string
  emailAddress: string
  customerInfoEnabled: boolean
  formError: string | null
  paymentMethod: EPaymentMethod | null
  setPaymentMethod: (method: EPaymentMethod) => void
  stripeElementsFieldsAllFilled: boolean
  submitting: boolean
  setQuantity: (key: string, quantity: number, minQuantity?: number) => void
  submit: (
    values: ICheckoutFormValues,
    stripe: ReactStripeElements.StripeProps,
    formikActions: FormikActions<IFormValues>
  ) => Promise<void>
  setElementFieldIsEmpty: (field: string, empty: boolean) => void
  payPalButtonId: string | null
  applePayShown: boolean
  setApplePayEnabled: (enabled: boolean) => void
  showPaymentOptions: boolean
  completeApplePayTransaction: (token: string, firstName: string, lastName: string, emailAddress: string) => Promise<void>
  hypotheticalInstallmentPlan: IHypotheticalInstallmentPlan | null
  paymentScheme: EPaymentScheme
  numberOfInstallments: number;
  setPaymentScheme: (scheme: EPaymentScheme) => void;
  setNumberOfInstallments: (n: number) => void;
  installmentPlanLoading: boolean;
  screen: ECheckoutScreen;
  setScreen: (screen: ECheckoutScreen) => void;
  creditCardEnabled: boolean;
}

const checkoutFormSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  emailAddress: yup.string().email().required(),
  quantity: yup.number().required().default(1)
});

const errorStyle = {
  color: "red"
}

type CheckoutProps = ICheckoutProps & ReactStripeElements.InjectedStripeProps

export const Checkout: React.SFC<CheckoutProps> = (props) => {
  const {
    cartItems,
    cartTotal,
    loading,
    setQuantity,
    stripe,
    submit,
    firstName,
    submitting,
    formError,
    lastName,
    emailAddress,
    customerInfoEnabled,
    setElementFieldIsEmpty,
    stripeElementsFieldsAllFilled,
    applePayShown,
    setApplePayEnabled,
    paymentMethod,
    setPaymentMethod,
    payPalButtonId,
    showPaymentOptions,
    completeApplePayTransaction,
    hypotheticalInstallmentPlan,
    numberOfInstallments,
    paymentScheme,
    setPaymentScheme,
    setNumberOfInstallments,
    installmentPlanLoading,
    screen,
    setScreen,
    creditCardEnabled
  } = props;

  if (loading === ELoadingState.Loading) return <Loading />

  const stripeElementsProps = {
    onChange: (event: any) => setElementFieldIsEmpty(event.elementType, event.empty),
    placeholder: "",
    className: "form-control",
    style: {
      base: {
        fontSize: "13pt",
        color: "#495057"
      }
    }
  }

  const payNowAmount = (paymentScheme === EPaymentScheme.Now || !hypotheticalInstallmentPlan) ? cartTotal : hypotheticalInstallmentPlan.payments[0].amount;

  const onSubmit = async (
    values: ICheckoutFormValues,
    formikActions: FormikActions<ICheckoutFormValues>
  ) => {
    if (!stripe) throw Error("unexpected - stripe is undefined")

    await submit(values, stripe, formikActions)
  }

  const correctNumberOfInstallmentPlanPayments = hypotheticalInstallmentPlan && (hypotheticalInstallmentPlan.payments.length === numberOfInstallments)

  const cart = (
    <Table>
      <tbody>
        {(
          cartItems.length === 1 &&
          cartItems[0].orderId &&
          hypotheticalInstallmentPlan &&
          paymentScheme === EPaymentScheme.Installments
        ) ?
          hypotheticalInstallmentPlan.payments.map(
            (payment, index) => (
              <>
                <tr className={"fadeIn"} key={`installment-${index}`}>
                  {(index === 0) ? (
                    <>
                      <td>
                        {cartItems[0].name}
                        <br />
                        <>Payment Today</>
                      </td>
                      <td />
                      <td className={"right-justify fadeIn"}><strong>{formatMonetaryValue(payment.amount)}</strong></td>
                    </>
                  ) : (
                      <>
                        <td>
                          <i>Remaining balance</i>
                        </td>
                        <td />
                        <td className={"right-justify fadeIn"}>{formatMonetaryValue(payment.amount)}</td>
                      </>
                    )}
                </tr>
              </>
            )
          )
          : cartItems.map(ci => (
            <>
              {paymentScheme === EPaymentScheme.Now && (
                <tr key={ci.key}>
                  <td style={{ width: "20rem" }}>
                    {ci.name}
                    {(ci.minimumQuantity && ci.minimumQuantity > 1) && (<><br /> <i>Minimum {ci.minimumQuantity}x</i></>)}
                  </td>
                  <td style={{ width: "14rem" }}>{
                    (ci.quantityEditable ?
                      (
                        <Form.Control
                          onChange={(e: any) => setQuantity(ci.key, e.target.value, ci.minimumQuantity || undefined)}
                          onClick={() => analytics.track(AnalyticsEvents.pages.checkout.clickedQuantityField)}
                          as="select">
                          {Array(10).fill(null).map((v, i) =>
                            <option value={i + 1}>{i + 1}x</option>
                          )}
                        </Form.Control>
                      )
                      :
                      (ci.quantity > 1) && (<p>{ci.quantity}x {formatMonetaryValue(ci.unitPrice)}</p>))
                  }
                  </td>
                  <td className="right-justify" style={{ width: "10rem" }}>{formatMonetaryValue(ci.unitPrice)}</td>
                </tr>
              )}
            </>
          ))
        }
      </tbody>
      {(cartItems.length > 0) && (paymentScheme === EPaymentScheme.Now || !installmentPlanLoading) ? (
        <tfoot>
          <tr>
            <td><strong>Total</strong></td>
            <td></td>
            <td className="right-justify"><strong>{formatMonetaryValue(cartTotal)}</strong></td>
          </tr>
        </tfoot>
      ) : <span />}
    </Table>
  )

  const paymentOptions = (
    <>
      <Row>
        <Col>
          <h4>Payment information</h4>
        </Col>
      </Row>
      <br />
      <Row>
        {creditCardEnabled && (<Col xs={4}>
          <Button
            variant="outline-primary"
            block
            onClick={() => {
              analytics.track(AnalyticsEvents.pages.checkout.selectedCreditCard)
              setPaymentMethod(EPaymentMethod.CreditCard)
            }}
          >
            Pay with credit card
                        </Button>
        </Col>)}
        {applePayShown &&
          (<Col xs={4}>
            <Button
              block
              variant="outline-primary"
              onClick={() => {
                analytics.track(AnalyticsEvents.pages.checkout.selectedApplePay)
                setPaymentMethod(EPaymentMethod.ApplePay)
              }}
            >
              Pay with Apple Pay
                          </Button>
          </Col>
          )}
        {payPalButtonId && (
          <Col xs={4}>
            <Button
              block
              variant="outline-primary"
              onClick={() => {
                analytics.track(AnalyticsEvents.pages.checkout.selectedPayPal)
                setPaymentMethod(EPaymentMethod.PayPal)
              }}
            >
              Pay with PayPal
            </Button>
          </Col>
        )}
      </Row>
    </>
  )

  return (
    <div className="checkout-page">
      {(screen !== ECheckoutScreen.ContactInformation) && (
        <div className="sticky-top cart-header-container">
          <CartHeader {...props} />
        </div>
      )}
      <div className="padding-top-2">
        <Container>
          <Formik<ICheckoutFormValues>
            validationSchema={checkoutFormSchema}
            isInitialValid={true}
            onSubmit={onSubmit}
            initialValues={{
              firstName: firstName,
              lastName: lastName,
              emailAddress: emailAddress,
              applePayToken: null
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
              submitForm
            }) => (
                <Form onSubmit={handleSubmit}>

                  {(screen === ECheckoutScreen.ContactInformation) && (
                    <div>
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <h4>Your order</h4>
                            </Col>
                          </Row>
                          <br />
                          {
                            (
                              cartItems.length === 1 &&
                              cartItems[0].orderId
                            ) ? (
                                <Row>
                                  <Col className="no-select">
                                    <div>
                                      <label>
                                        <input
                                          onChange={(e) => {
                                            setPaymentScheme(EPaymentScheme.Now)
                                          }}
                                          checked={(paymentScheme === EPaymentScheme.Now)}
                                          type="radio"
                                          value="option3" className={"mr2"} />
                                        &nbsp;&nbsp;Entire balance now ({formatMonetaryValue(cartTotal)})
                            </label>
                                    </div>
                                  </Col>
                                </Row>
                              ) : <span />
                          }
                          <Row className="section">
                            <Col>
                              {cart}
                            </Col>
                          </Row>
                          <Row className="section">
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                  disabled={!customerInfoEnabled}
                                  type="text"
                                  name="firstName"
                                  value={values.firstName}
                                  onChange={handleChange}
                                  onClick={() => analytics.track(AnalyticsEvents.pages.checkout.clickedFirstNameField)}
                                />
                              </Form.Group>
                            </Col>
                          </Row><Row>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                  disabled={!customerInfoEnabled}
                                  type="text"
                                  name="lastName"
                                  value={values.lastName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onClick={() => analytics.track(AnalyticsEvents.pages.checkout.clickedLastNameField)}
                                />
                              </Form.Group>
                            </Col>
                          </Row><Row>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                  disabled={!customerInfoEnabled}
                                  type="text"
                                  name="emailAddress"
                                  value={values.emailAddress}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onClick={() => analytics.track(AnalyticsEvents.pages.checkout.clickedEmailAddressField)}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <Button
                                size="lg"
                                style={{ minWidth: "10rem" }}
                                disabled={!values.firstName || !values.lastName || !values.emailAddress || submitting}
                                onClick={() => setScreen(ECheckoutScreen.PaymentMethodSelection)}
                              >
                                Continue to Payment
                          </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {(screen === ECheckoutScreen.PaymentMethodSelection || screen === ECheckoutScreen.PaymentInformation) && (
                    <>
                      <Row>
                        <Col>
                          {paymentOptions}
                          {stripe && (
                            <Row className="section">
                              <Col>
                                <PaymentRequestButton
                                  firstName={values.firstName}
                                  lastName={values.lastName}
                                  emailAddress={values.emailAddress}
                                  stripe={stripe}
                                  shown={(paymentMethod === EPaymentMethod.ApplePay)}
                                  label={"Vestaboard Online Payment"}
                                  amount={payNowAmount}
                                  submitting={submitting}
                                  setApplePayEnabled={setApplePayEnabled}
                                  completeApplePayTransaction={(token: string) => {
                                    setFieldValue("applePayToken", token)
                                    submitForm();
                                  }}
                                  setApplePayFailure={(failure: boolean) => console.log("failure", failure)}
                                />
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                      {(payPalButtonId && paymentMethod === EPaymentMethod.PayPal) && (
                        <Row>
                          <Col>
                            <a
                              href={`https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=${payPalButtonId}`}>
                              <img src={buyLogoImage}></img>
                            </a>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {(paymentMethod === EPaymentMethod.CreditCard || screen === ECheckoutScreen.PaymentInformation) && (
                    <>
                      <Row className="section">
                        <Col>
                          <Form.Group
                            onClick={() => analytics.track(AnalyticsEvents.pages.checkout.creditCardNumberClicked)}>
                            <Form.Label>Card number</Form.Label>
                            <CardNumberElement {...stripeElementsProps} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group onClick={() => analytics.track(AnalyticsEvents.pages.checkout.cvcClicked)}>
                            <Form.Label>CVC</Form.Label>
                            <CardCVCElement {...stripeElementsProps} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            onClick={() => analytics.track(AnalyticsEvents.pages.checkout.expirationDateClicked)}>
                            <Form.Label>Expiration</Form.Label>
                            <CardExpiryElement {...stripeElementsProps} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Form.Group onClick={() => analytics.track(AnalyticsEvents.pages.checkout.postalCodeClicked)}>
                            <Form.Label>Postal code</Form.Label>
                            <PostalCodeElement {...stripeElementsProps} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Button
                            size="lg"
                            style={{ minWidth: "10rem" }}
                            disabled={!stripeElementsFieldsAllFilled || !isValid || submitting}
                            type="submit"
                          >
                            {submitting ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                <span>Charge {formatMonetaryValue(payNowAmount)}</span>
                              )}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col style={errorStyle}>
                      {formError}
                    </Col>
                  </Row>
                  <br />
                  <hr />
                  <br />
                  <Row>
                    <Col md={8}>
                      <p className="fine-print">
                        By continuing you agree to our <a href="https://www.vestaboard.com/legal">Privacy Policy and Terms &amp; Conditions.</a>
                      </p>
                      <p className="fine-print"><strong>Interested in financing options?</strong></p>
                      <p className="fine-print">Architects and designers in private practice or at companies may contact us for a special quote via <a href="https://vestaboard.com/trade">our trade page</a>.</p>
                      <p className="fine-print">To finance your personal purchase for a low monthly price, <a href="https://vestaboard.com/financing">sign up here</a> to be notified.</p>
                    </Col>
                  </Row>
                </Form>
              )}
          </Formik>
        </Container>
      </div>
    </div>
  )
}
