import React from 'react';
import { css, cx } from "emotion";

export interface IOrderHeaderProps {
  className?: string;
}

const OrderHeader: React.FC<IOrderHeaderProps> = props => {
  return (
    <h1 className={cx(css({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontFamily: '"HelveticaNeueLTStd-Bd", sans-serif',
      fontSize: '1.5em',
      fontWeight: 'bold',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.47px',
      color: '#34475b',
      height: '20px',
      marginTop: '0px',
      marginBottom: '32px'
    }), props.className)}>
      {props.children}
    </h1>
  );
};

export default OrderHeader;
