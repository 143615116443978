import * as React from "react";
import { ICheckoutProps } from "../page/checkout-page";
import { Table, Form, Container } from "react-bootstrap";
import { EPaymentScheme } from "../../container/checkout-page";
import { formatMonetaryValue } from "../../lib/format";
import { AnalyticsEvents, analytics } from "../../lib/shared";
import productImage from "../../product.png";

type CartHeaderProps = ICheckoutProps;

const CartHeader: React.FC<CartHeaderProps> = (props) => {
  const {
    cartItems,
    cartTotal,
    loading,
    setQuantity,
    submit,
    firstName,
    submitting,
    formError,
    lastName,
    emailAddress,
    customerInfoEnabled,
    setElementFieldIsEmpty,
    stripeElementsFieldsAllFilled,
    applePayShown,
    setApplePayEnabled,
    paymentMethod,
    setPaymentMethod,
    payPalButtonId,
    showPaymentOptions,
    completeApplePayTransaction,
    hypotheticalInstallmentPlan,
    numberOfInstallments,
    paymentScheme,
    setPaymentScheme,
    setNumberOfInstallments,
    installmentPlanLoading
  } = props;

  const payNowAmount = (paymentScheme === EPaymentScheme.Now || !hypotheticalInstallmentPlan) ? cartTotal : hypotheticalInstallmentPlan.payments[0].amount;

  const firstCartItem = cartItems[0];
  const formattedTotal = formatMonetaryValue(payNowAmount)
  let cartItemDescription = firstCartItem.name

  if (cartItems.length > 1) {
    cartItemDescription = `${cartItemDescription} (+${cartItems.length - 1} items)`
  }

  return (
    <div className="cart-header">
      <Container>
        <div className="cart-header-items">
          <div className="cart-header-left-items">
            <div className="image">
              <img src={productImage} />
            </div>
            <div className="title">
              {cartItemDescription}
            </div>
          </div>
          <div className="cart-header-right-items">
            <div className="total"><strong>{formattedTotal}</strong></div>
          </div>
        </div>
      </Container>

    </div>
  )
}

export default CartHeader;