import React from 'react';
import classNames from 'classnames';
import { Collapse } from "react-bootstrap";
import { cx, css } from 'emotion';

export interface ICollapsibleProps {
  isOpen?: boolean;
  onOpen?(open: boolean): void;
  heading: React.ReactNode;

  className?: string;
  headingClassName?: string;
  bodyClassName?: string;
  disabled?: boolean;
}

const Collapsible: React.FC<ICollapsibleProps> = (props) => {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = React.useState(props.isOpen || false);
  const isOpen = uncontrolledIsOpen;
  const onOpen = (newIsOpen: boolean) => {
    setUncontrolledIsOpen(newIsOpen);
    props.onOpen && props.onOpen(newIsOpen);
  };

  return (
    <div className={props.className}>
      <div
        className={classNames('pointer flex justify-between', props.headingClassName)}
        onClick={() => !props.disabled && onOpen(!isOpen)}
      >
        <div className={cx(css({
          width: '100%'
        }))}>{props.heading}</div>{!props.disabled && (<span>{isOpen ? '▲' : '▼'}</span>)}
      </div>
      <div>
        <Collapse in={isOpen}>
          <div className={props.bodyClassName}>{props.children}</div>
        </Collapse>
      </div>
    </div>
  );
};

export default Collapsible;
