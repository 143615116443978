import * as React from "react";
import { ELoadingState } from "../../type/shared";
import { IApiCustomerResponse, IWithRelatedCompanies, IWithRelatedOrdersAndOffers } from "../../lib/api";
import { safe, analytics, AnalyticsEvents } from "../../lib/shared";
import { Button, Card, CardDeck, Jumbotron, Alert } from "react-bootstrap"
import { Link } from "react-router-dom";
import { formatTimestamp, formatMonetaryValue } from "../../lib/format";
import { Container, Table, Row, Col } from "react-bootstrap";
import { sortBy, reverse } from "lodash";
import { Loading } from "../atom/loading";
import vestaboardImage from "../../image/yellowmidcenturychairs.jpg";

export interface ICustomerPageViewModel {
  loading: ELoadingState;
  error?: string | null;
  customer?: null | (IApiCustomerResponse & IWithRelatedCompanies & IWithRelatedOrdersAndOffers)
}

type CustomerPageProps = ICustomerPageViewModel

export const CustomerPage = ({
  loading,
  customer
}: CustomerPageProps) => {
  const {
    firstName,
    lastName,
    emailAddress,
    companies,
    orders,
    totalBalance
  } = safe(customer)

  if (loading == ELoadingState.Loading || !customer) {
    return <Loading />
  }

  const sortedOrders = reverse(sortBy(orders([]), (order) => order.createdAt))

  const orderCards = sortedOrders.map(order => (
    <Card key={order.id} bg="light">
      <Card.Body>
        <strong>Order #{order.friendlyIdentifier}</strong>
        <p>{formatTimestamp(order.createdAt)}</p>
        <p>{order.quantity}x</p>
        <p>{formatMonetaryValue(!order.offer ? 0 : (order.offer.discountedPrice || order.offer.retailPrice) * order.quantity)}</p>
        <p>{formatMonetaryValue(order.currentPaidAmount)} paid</p>
        <p>{formatMonetaryValue(order.currentBalance)} to pay</p>
        <Link
          onClick={() => analytics.track(AnalyticsEvents.pages.customer.orderDetailCTA)}
          to={`/order/${order.id}`}>
          <Button size="sm" variant="outline-primary">Details</Button>
        </Link>
      </Card.Body>
    </Card >
  ))

  const orderRows = sortedOrders.map(
    (order) => (
      <tr key={order.id}>
        <td>{formatTimestamp(order.createdAt)}</td>
        <td>{formatMonetaryValue(order.currentBalance)}</td>
        <td>
          <Link
            onClick={() => analytics.track(AnalyticsEvents.pages.customer.orderDetailCTA)}
            to={`/order/${order.id}`}>
            <Button size="sm" variant="outline-primary">Details</Button>
          </Link>
        </td>
      </tr>
    )
  )

  const noOrderRows = (orders([]).length === 0)

  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <Col>
              <Jumbotron className="vb-jumbotron" style={{ textAlign: "center" }} fluid>
                <h5>Welcome to your Customer Portal,</h5>
                <h3>{firstName()} {lastName()}</h3>
              </Jumbotron>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={8}>
              {noOrderRows ?
                <span />
                :
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Purchase Date</th>
                      <th>Balance Remaining</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {orderRows}
                  </tbody>
                </Table>
              }
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className="text-muted">Your balance (plus shipping &amp; taxes) is due prior to shipment. If no order is showing or the balance doesn’t look right to you, <a href="https://www.vestaboard.com/help/kb-tickets/new">contact us</a> for help.</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
