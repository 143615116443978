import * as React from "react";
import { useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { CheckoutContainer } from "./checkout-page";
import { CustomerPageContainer } from "./customer-page";
import { NewsPageContainer } from "./news-page";
import { OrderDetailPageContainer } from "./order-detail-page";
import { Header } from "../component/organism/header";
import { Navigation } from "../component/organism/navigation";
import { Footer } from "../component/organism/footer";
import { useAppContainer, AppState } from "../hook/app";
import { AppTemplate } from "../component/template/app-template";
import history from "../history"
import { StripeProvider } from "react-stripe-elements"
import { getEnv } from "../lib/env";
import { Loading } from "../component/atom/loading";
import { ProtectedRoute, Tracker, analytics, AnalyticsEvents, logout, SignUp } from "../lib/shared";
import queryString from "query-string"
import { AppUserPageContainer } from "./app-user-container";
import { UpdatesPageContainer } from "./updates-page-container";
import { ArticleContainer } from "./article-container";
import { bugsnagClient } from "../App";
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';


type AppContainerProps = {}

export const AppContext = React.createContext<AppState | null>(null)

const Callback: React.SFC<{}> = (props: {}) => {
  useEffect(
    () => {
      const qs = queryString.parse(window.location.search)
      const token = qs["t"]
      if (token) {
        localStorage.setItem("vestaboardAccessToken", token)
        window.location.href = "/"
      }
    }, []
  )

  return (<span />)
}

const Logout: React.SFC<any> = (props) => {
  useEffect(
    () => {
      localStorage.removeItem("vestaboardAccessToken")
      window.location.href = "/login"
    }, []
  )

  return (<span />)
}

export const AppContainer: React.SFC<AppContainerProps> = (props) => {
  const appState = useAppContainer()

  const auth0Config = {
    domain: "vestaboard.auth0.com",
    clientId: "O3VAhQ2D9oP45IZfd91cOg27AtdgNsL4",
    redirectUri: window.location.origin
  }

  return (
    <AppTemplate>
      <Auth0Provider {...auth0Config}>
        <StripeProvider apiKey={getEnv("stripe_publishable_key")}>
          <AppContext.Provider value={appState}>
            {/*<LoginButton />*/}
            <div className={"wrap"}>
              <Header />
              <Navigation />
              <div className="page clear-top clear-bottom">
                <Router history={history}>
                  <Switch>
                    <Route path="/news" component={NewsPageContainer} />
                    <Route path="/order/:id" component={
                      (props: any) => {
                        analytics.track(AnalyticsEvents.pages.orderDetail.load)
                        return <ProtectedRoute><OrderDetailPageContainer {...props} /></ProtectedRoute>
                      }
                    } />
                    <Route path="/signup" exact render={() => <SignUp />} />
                    <Route path="/" exact render={() => <ProtectedRoute><CustomerPageContainer /></ProtectedRoute>} />
                    <Route path="/payment" render={() => <ProtectedRoute><CheckoutContainer /></ProtectedRoute>} />
                    <Route path="/preferences" render={() => <ProtectedRoute><AppUserPageContainer /></ProtectedRoute>} />
                    <Route path="/updates/:slug" render={(props) => <ProtectedRoute><ArticleContainer {...props} /></ProtectedRoute>} />
                    <Route path="/updates" render={(props) => <ProtectedRoute><UpdatesPageContainer {...props} /></ProtectedRoute>} />
                    <Route path="/logout" render={() => <Logout />} />
                  </Switch>
                </Router>
              </div>
            </div>
            <div className="footer fixed-bottom">
              <div className={"container"}>
                <div className={"row"}>
                  <div className={"col align-self-center"}>
                    <p className={"text-muted text-center small"}>Need help? Chat or <a href="https://www.vestaboard.com/help/kb-tickets/new">contact us!</a><br />&copy; 2020 Vestaboard, Inc.</p>
                  </div>
                </div>
              </div>
            </div>
          </AppContext.Provider>
        </StripeProvider>
      </Auth0Provider>
    </AppTemplate >
  )
}

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button onClick={() => loginWithRedirect()}>Log In</button>;
};
