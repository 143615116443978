import * as React from "react";
import { useEffect, useState } from "react"
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { usePatchRequest } from "../hook/api";
import { useCustomer } from "../hook/data/customer";
import { ProtectedRoute } from "../lib/shared";
import { Loading } from "../component/atom/loading";
import { ELoadingState } from "../type/shared";
import { css } from "emotion";

type AppUserPageProps = any

export const AppUserPageContainer: React.SFC<AppUserPageProps> = (props) => {
  const { data: customer, loading: customerLoading } = useCustomer("me", "")

  const [interestedInApi, setInterestedInApi] = useState(false)
  const [interestedInBetaTesting, setInterestedInBetaTesting] = useState(false)
  const [interestedInTrade, setInterestedInTrade] = useState(false)

  const { doPatch, loading } = usePatchRequest(`/customer`)

  const [submitting, setSubmitting] = useState(false)

  const save = async () => {
    setSubmitting(true)
    await doPatch({
      interestedInApi,
      interestedInBetaTesting,
      interestedInTrade
    })
    setSubmitting(false)
  }

  useEffect(
    () => {
      if (customer) {
        setInterestedInApi(customer.interestedInApi)
        setInterestedInBetaTesting(customer.interestedInBetaTesting)
        setInterestedInTrade(customer.interestedInTrade)
      }
    }, [customer]
  )

  return (
    <ProtectedRoute>
      {customerLoading === ELoadingState.Loading ? (
        <Loading />
      ) : (
          <Container>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Check type={"checkbox"} id={`beta-testing`}>
                    <Form.Check.Input type={"checkbox"} checked={interestedInBetaTesting} onChange={(e) => {
                      setInterestedInBetaTesting(e.target.checked);
                    }} />
                    <Form.Check.Label className="app-option-label">
                      <strong>Beta testing</strong>
                      <p>I am interested in being an early reviewer of the software</p>
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check type={"checkbox"} id={`api`}>
                    <Form.Check.Input type="checkbox" checked={interestedInApi} onChange={(e) => {
                      setInterestedInApi(e.target.checked);
                    }} />
                    <Form.Check.Label className="app-option-label">
                      <strong>Developers</strong>
                      <p>I am interested in Vestaboard API documentation</p>
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check type={"checkbox"} id={`publishing`}>
                    <Form.Check.Input type="checkbox" checked={interestedInTrade} onChange={(e) => {
                      setInterestedInTrade(e.target.checked);
                    }} />
                    <Form.Check.Label className="app-option-label">
                      <strong>Trade Program</strong>
                      <p>I am an architect and design professional or otherwise interested in trade program related
                      news</p>
                    </Form.Check.Label>
                  </Form.Check>
                </Form>
              </Col>
            </Row>
            <Row>
              <Button disabled={submitting} onClick={() => save()} variant="primary" className={css({
                marginLeft: 64
              })}>Update Preferences</Button>
            </Row>
          </Container>
        )}
    </ProtectedRoute>
  )
}
