import * as React from "react";

import { Alert, Button, Col, Container, Form, FormControl, Jumbotron, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import { AnalyticsEvents, analytics, safe } from "../../lib/shared";
import GeneralInfoBox, { GeneralInfoTable } from "../general-info-box";
import { IApiOrderResponse, IWithRelatedOffer, OrderStatus, orderedOrderStatuses } from "../../lib/api";
import { css, cx } from "emotion";
import { formatMonetaryValue, formatTimestamp } from "../../lib/format"

import { ELoadingState } from "../../type/shared";
import { FiChevronLeft } from 'react-icons/fi';
import { Input } from "../atom/input";
import { Link } from "react-router-dom";
import { Loading } from "../atom/loading";
import OrderHeader from "../order-header";
import VerticalStepper from "../vertical-stepper";
import history from "../../history";
import { useGetRequest } from "../../hook/api";
import vestaboardImage from "../../image/yellowmidcenturychairs.jpg"

export interface IOrderDetailPageProps {
  loading: ELoadingState;
  order: null | (IApiOrderResponse & IWithRelatedOffer);
  city: string | null;
  country: string | null;
  setCity: (value: string) => void;
  setCountry: (value: string) => void;
  cityEditing: boolean;
  countryEditing: boolean;
  setCityEditing: (value: boolean) => void;
  setCountryEditing: (value: boolean) => void;
  saveCountry: (value: string) => void;
  saveCity: (value: string) => void;
  validCountries: string[];
  refetchOrder: () => void;
  saveOrderShippingInformation: (
    company: string | null,
    contact: string | null,
    streetAddress: string | null,
    city: string | null,
    stateProvince: string | null,
    postalCode: string | null,
    country: string | null,
    phone: string | null,
    localDapConfirmation: boolean | null
  ) => any;
}

type OrderDetailPageProps = IOrderDetailPageProps

interface EditableFieldProps {
  disabled?: boolean;
  name: string;
  value: string;
  updateValue: (value: string) => void;
  editing: boolean;
  setEditing?: (value: boolean) => void;
  save?: () => void;
  emptyValue?: string;
  hideSave?: boolean;
}

const EditableField: React.SFC<EditableFieldProps> = ({
  value,
  updateValue,
  editing,
  name,
  save,
  setEditing,
  disabled,
  emptyValue,
  hideSave
}) => {
  return (
    <>
      {editing ? (
        <Form>
          <Form.Row>
            <Col>
              <Form.Control
                size="sm"
                type="text"
                name={name}
                value={value}
                onChange={(event: any) => updateValue(event.target.value)}
              />
            </Col>
            <Col>
              {!hideSave && (
                <Button variant="primary" onClick={() => save && save()} size="sm">
                  Save
                </Button>
              )}
            </Col>
          </Form.Row>
        </Form>
      ) : (
          <Form>
            <Form.Row>
              <Col>
                {value}
                {(!disabled && !value) && (
                  <Button variant="outline-primary" onClick={() => setEditing && setEditing(true)} size="sm">
                    {emptyValue}
                  </Button>
                )}
              </Col>
              <Col>
                {(!hideSave && !disabled && value) && (
                  <Button variant="outline-primary" onClick={() => setEditing && setEditing(true)} size="sm">
                    Edit
                  </Button>
                )}
              </Col>
            </Form.Row>
          </Form>
        )
      }
    </>
  )
}

const orderStatusHeaderStyles = (isCurrentStatus: boolean) => ({
  borderBottom: isCurrentStatus ? "" : "1px solid black",
  fontWeight: isCurrentStatus ? 500 : undefined
})

interface IAccordionSectionProps {
  title: string;
  open: boolean;
  onClick: () => void;
}

type AccordionSectionProps = IAccordionSectionProps

const accordionHeaderStyles = {
  borderBottom: "1px solid black"
}

const AccordionSection: React.FC<AccordionSectionProps> = (props) => {
  return (
    <div>
      <div style={accordionHeaderStyles} onClick={props.onClick}>
        <h4>{props.title}</h4>
      </div>
      {props.open ? (
        <div>
          <Container>
            {props.children}
          </Container>
        </div>
      ) : null}
    </div>
  )
}


export const OrderDetailPage: React.SFC<OrderDetailPageProps> = ({
  order,
  loading,
  city,
  country,
  setCity,
  setCountry,
  cityEditing,
  setCityEditing,
  countryEditing,
  setCountryEditing,
  saveCountry,
  saveCity,
  validCountries,
  refetchOrder,
  saveOrderShippingInformation
}) => {
  const {
    quantity,
    createdAt,
    currentPaidAmount,
    currentBalance,
    friendlyIdentifier,
    shipmentLetter,
    estimatedDelivery,
    shippingCountry,
    shippingCity,
    phone,
    lineItemCharges,
    taxesBalance,
    dutiesBalance,
    shippingBalance,
    shippingCompany,
    shippingContact,
    shippingPostalCode,
    shippingStateProvince,
    shippingStreetAddress,
    orderStatus,
    trackingLink,
    dutyPaidTiming,
    dapConfirmation,
    offer: {
      retailPrice,
      discountedPrice,
      productName
    }
  } = safe(order)

  const { data: countryMappings } = useGetRequest<any>("/countries", "GET")

  const countries = countryMappings ? Object.keys(countryMappings as any) : []

  const [debugMode, setDebugMode] = React.useState(false)
  const [generalInfoMobileShown, setGeneralInfoMobileShown] = React.useState(false)

  const [localCompanyName, setLocalCompanyName] = React.useState(shippingCompany())
  const [localContactName, setLocalContactName] = React.useState(shippingContact())
  const [localStreetAddress, setLocalStreetAddress] = React.useState(shippingStreetAddress())
  const [localStateProvince, setLocalStateProvince] = React.useState(shippingStateProvince())
  const [localPostalCode, setLocalPostalCode] = React.useState(shippingPostalCode())
  const [localCity, setLocalCity] = React.useState(shippingCity())
  const [localCountry, _setLocalCountry] = React.useState(shippingCountry())
  const [localPhone, setLocalPhone] = React.useState(phone())
  const [localDapConfirmation, setLocalDapConfirmation] = React.useState(dapConfirmation())


  const setLocalCountry = (newCountry: string | null | undefined) => {
    if (newCountry != localCountry) {
      setLocalStateProvince(null)
      setLocalStreetAddress(null)
      setLocalCity(null)
      setLocalPostalCode(null)
    }
    _setLocalCountry(newCountry)
  }


  const [saving, setSaving] = React.useState(false)

  React.useEffect(
    () => {
      setLocalCompanyName(shippingCompany())
      setLocalContactName(shippingContact())
      setLocalStreetAddress(shippingStreetAddress())
      setLocalStateProvince(shippingStateProvince())
      setLocalPostalCode(shippingPostalCode())
      setLocalCity(shippingCity())
      _setLocalCountry(shippingCountry())
      setLocalPhone(phone())
      setLocalDapConfirmation(dapConfirmation())
    }, [order]
  )

  const saveShippingInformation = async () => {
    setSaving(true)
    await saveOrderShippingInformation(
      localCompanyName || null,
      localContactName || null,
      localStreetAddress || null,
      localCity || null,
      localStateProvince || null,
      localPostalCode || null,
      localCountry || null,
      localPhone || null,
      localDapConfirmation || null
    )
    await refetchOrder()
    setSaving(false)
  }

  const [accordionOpen, setAccordionOpen] = React.useState("")

  if (loading === ELoadingState.Loading || !order) {
    return <Loading />
  }

  const countrySelectStyle = {
    width: "150px"
  }

  const productTotal = (discountedPrice(0) * quantity(1))
  const totalCost = (productTotal + dutiesBalance(0) + taxesBalance(0) + shippingBalance(0))
  const balance = currentBalance(0)
  const productPaidBalance = order.lineItemCharges.filter(li => li.description.indexOf("Vestaboard") > -1)[0].balance || 0
  const paidAmount = productTotal - productPaidBalance

  const generalOrderInfoFields = {
    title: "General Order Info",
    fields: [
      [
        <strong>Order</strong>,
        friendlyIdentifier()
      ],
      [
        <strong>Quantity</strong>,
        <>{quantity()}</>
      ],
      [
        <strong>Purchase Date</strong>,
        formatTimestamp(createdAt())
      ],
      [
        <strong>Estimated Delivery</strong>,
        estimatedDelivery() || <i>To be determined</i>
      ]
    ]
  }

  const expectedFlag = (() => {
    switch (dutyPaidTiming()) {
      case "DAP":
        return "Expected ";
      default:
        return ""
    }
  })()

  const taxName = (() => {
    if (localCountry) {
      const existing = countryMappings[localCountry]
      return existing || "Local Taxes"
    } else {
      return "Local Taxes"
    }
  })()

  const balanceInfoFields = {
    fields: [
      [
        <strong>Price</strong>,
        formatMonetaryValue(discountedPrice())
      ],
      [
        <strong>Amount paid</strong>,
        formatMonetaryValue(paidAmount)
      ],
      [
        <strong>Shipping</strong>,
        <>{(!!shippingBalance()) ? formatMonetaryValue(shippingBalance()) : "To be determined"}</>
      ],
      [
        <strong>{expectedFlag}{taxName}</strong>,
        <>{(!!taxesBalance()) ? formatMonetaryValue(taxesBalance()) : "To be determined"}</>
      ],
      [
        <strong>{expectedFlag}Duties</strong>,
        <>{(!!dutiesBalance()) ? formatMonetaryValue(dutiesBalance()) : "To be determined"}</>
      ],
      [
        <strong>Balance Remaining</strong>,
        formatMonetaryValue(currentBalance())
      ]
    ],
    title: "Balance Info"
  }

  const shippingFields = (readOnly: boolean) => ({
    title: "Shipping Info",
    fields: [
      [
        <strong>Company name</strong>,
        <EditableField
          name="company"
          value={localCompanyName || ""}
          updateValue={(value: string) => setLocalCompanyName(value)}
          editing={!readOnly}
          disabled={readOnly}
          hideSave
        />
      ],
      [
        <strong>Contact name</strong>,
        <EditableField
          name="contact"
          value={localContactName || ""}
          updateValue={(value: string) => setLocalContactName(value)}
          editing={!readOnly}
          disabled={readOnly}
          hideSave
        />
      ],
      [
        <strong>Contact phone</strong>,
        <EditableField
          name="contact"
          value={localPhone || ""}
          updateValue={(value: string) => setLocalPhone(value)}
          editing={!readOnly}
          disabled={readOnly}
          hideSave
        />
      ],
      [
        <strong>Street address</strong>,
        <EditableField
          name="streetAddress"
          value={localStreetAddress || ""}
          updateValue={(value: string) => setLocalStreetAddress(value)}
          editing={!readOnly}
          disabled={readOnly}
          hideSave
        />
      ],
      [
        <strong>City</strong>,
        <EditableField
          name="city"
          value={localCity || ""}
          updateValue={(value: string) => setLocalCity(value)}
          editing={!readOnly}
          disabled={readOnly}
          hideSave
        />
      ],
      [
        <strong>{regionNameForCountry}</strong>,
        <EditableField
          name="stateRegion"
          value={localStateProvince || ""}
          updateValue={(value: string) => setLocalStateProvince(value)}
          editing={!readOnly}
          disabled={readOnly}
          hideSave
        />
      ],
      [
        <strong>Postal Code</strong>,
        <EditableField
          name="postalCode"
          value={localPostalCode || ""}
          updateValue={(value: string) => setLocalPostalCode(value)}
          editing={!readOnly}
          disabled={readOnly}
          hideSave
        />
      ],
      [
        <strong>Country</strong>,
        <span>{localCountry}</span>
      ]
    ]
  })

  const saveShippingInformationDisabled = !(
    localContactName && localStreetAddress && localStateProvince && localPostalCode && localCountry && localCity && localPhone
  )

  let regionNameForCountry = ""
  let regionSelector = <span />

  const CANADIAN_PROVINCES = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Northwest Territories",
    "Nunavut",
    "Yukon"
  ]

  const US_STATES = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "American Samoa",
    "Guam",
    "Northern Mariana Islands",
    "Puerto Rico",
    "U.S. Virgin Islands"
  ]

  switch (localCountry) {
    case "United States":
      regionNameForCountry = "State";
      regionSelector = (
        <Form.Group controlId="stateRegion">
          <Form.Label>State</Form.Label>
          <Form.Control as="select" value={localStateProvince || ""} onChange={(e: any) => setLocalStateProvince(e.target.value)}>
            <option>Select a state</option>
            {US_STATES.map(state => (
              <option value={state}>{state}</option>
            ))}
          </Form.Control>
        </Form.Group>
      )
      break;
    case "Canada":
      regionNameForCountry = "Province";
      regionSelector = (
        <Form.Group controlId="stateRegion">
          <Form.Label>Province</Form.Label>
          <Form.Control as="select" value={localStateProvince || ""} onChange={(e: any) => setLocalStateProvince(e.target.value)}>
            <option>Select a province</option>
            {CANADIAN_PROVINCES.map(state => (
              <option value={state}>{state}</option>
            ))}
          </Form.Control>
        </Form.Group>
      )
      break;
    default:
      regionNameForCountry = "State / Region";
      regionSelector = (
        <Form.Group controlId="stateRegion">
          <Form.Label>{regionNameForCountry}</Form.Label>
          <Form.Control value={localStateProvince || ""} onChange={(e: any) => setLocalStateProvince(e.target.value)} type="text" />
        </Form.Group>
      )
      break;
  }


  const generalInfoBox = (
    <GeneralInfoBox>
      {[
        {
          heading: 'General info',
          isOpen: true,
          render: <GeneralInfoTable>
            <tbody>
              {generalOrderInfoFields.fields.map(field => (
                <tr><td className={cx("w-100 nowrap", css({
                  lineHeight: '26px',
                  padding: '14px 0px'
                }))}><strong>{field[0]}</strong><br />{field[1]}</td></tr>
              ))}
            </tbody>
          </GeneralInfoTable>
        },
        {
          heading: 'Balance info', // TODO: Replace with real columns
          render: <GeneralInfoTable>
            <tbody>
              {balanceInfoFields.fields.map(field => (
                <tr><td className={cx("w-100 nowrap", css({
                  lineHeight: '26px',
                  padding: '14px 0px'
                }))}><strong>{field[0]}</strong><br />{field[1]}</td></tr>
              ))}
            </tbody>
          </GeneralInfoTable>
        },
        {
          heading: 'Shipping info', // TODO: Replace with real columns
          render: <GeneralInfoTable>
            <tbody>
              {shippingFields(true).fields.map((field, key) => (
                <React.Fragment key={key}>
                  <tr><td className={cx("w-100 nowrap", css({
                    lineHeight: '26px',
                    padding: '14px 0px'
                  }))}>
                    <strong>{field[0]}</strong>
                    <br />{field[1]}
                  </td></tr>
                </React.Fragment>
              ))}
            </tbody>
          </GeneralInfoTable>
        }
      ]}
    </GeneralInfoBox>
  )


  const makePayment = async () => {
    analytics.track(AnalyticsEvents.pages.orderDetail.balanceCTA)
    await saveShippingInformation()
    history.push(`/payment?orderIds=${order.id}`)
  }

  return (
    <Container>
      <OrderHeader className={cx("mt5 mb5", css({
        justifyContent: 'space-between',
        fontSize: 40,
        paddingTop: 40,
        paddingBottom: 40,
        whiteSpace: 'nowrap',
        '@media(max-width: 960px)': {
          fontSize: 21,
          paddingTop: 21,
          paddingBottom: 21,
          fontWeight: 'bold'
        }
      }))}>
        {generalInfoMobileShown ?
          (<div onClick={() => {
            setGeneralInfoMobileShown(!generalInfoMobileShown)
          }} className={cx(css({
            cursor: 'pointer',
            color: '#000',
            '&:hover': {
              textDecoration: 'none'
            },
            svg: {
              fontSize: '32px'
            }
          }))}>
            <FiChevronLeft />Order status #{friendlyIdentifier()}
          </div>) : `Order status #${friendlyIdentifier()}`}
        <Button
          className={cx("dn-l", css({
            minWidth: '100px',
            textAlign: 'right',
            cursor: 'pointer'
          }))}
          size="sm"
          variant="link"
          onClick={() => setGeneralInfoMobileShown(!generalInfoMobileShown)}
        >Order details</Button>
      </OrderHeader>
      <div className="flex justify-between" style={{}}>
        {!generalInfoMobileShown && (
          <div className={"w-100-m w-50-l"}>
            <VerticalStepper debugMode={debugMode} currentState={order.orderStatus}>
              {orderedOrderStatuses.map((status, index) => {
                let body = null
                let header = null

                switch (status) {
                  case "ConfirmYourShippingAddress":
                    body = <>
                      <p>If incorrect or blank, please update now. We will use the address provided to prepare your final balance and order confirmation.</p>
                      <p>If you have reserved multiple Vestaboards and plan to have them delivered to different locations, please <a href="https://www.vestaboard.com/help/kb-tickets/new">contact us</a> before confirming your address(es).</p>
                      <Form className={"mt3"}>
                        <Row>
                          <Col>
                            <Form.Group controlId="company">
                              <Form.Label>Company Name</Form.Label>
                              <Form.Control value={localCompanyName || ""} onChange={(e: any) => setLocalCompanyName(e.target.value)} type="text" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group controlId="contactName">
                              <Form.Label>Contact Name</Form.Label>
                              <Form.Control value={localContactName || ""} onChange={(e: any) => setLocalContactName(e.target.value)} type="text" />
                            </Form.Group>
                          </Col>

                        </Row>
                        <Row>
                          <Col>
                            <Form.Group controlId="contactNumber">
                              <Form.Label>Contact Phone</Form.Label>
                              <Form.Control value={localPhone || ""} onChange={(e: any) => setLocalPhone(e.target.value)} type="text" />
                            </Form.Group>
                          </Col>

                        </Row>
                        <Row>
                          <Col>
                            <Form.Group controlId="country">
                              <Form.Label>Country</Form.Label>
                              <Form.Control as="select" value={localCountry || ""} onChange={(e: any) => setLocalCountry(e.target.value)}>
                                <option value={""}>Select a country</option>
                                {validCountries.map(
                                  country => (<option value={country}>{country}</option>)
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col>

                        </Row>

                        {localCountry && (
                          <>
                            <Row>
                              <Col>
                                <Form.Group controlId="streetAddress">
                                  <Form.Label>Street Address</Form.Label>
                                  <Form.Control value={localStreetAddress || ""} onChange={(e: any) => setLocalStreetAddress(e.target.value)} type="text" />
                                </Form.Group>
                              </Col>

                            </Row>
                            <Row>
                              <Col>
                                <Form.Group controlId="city">
                                  <Form.Label>City</Form.Label>
                                  <Form.Control value={localCity || ""} onChange={(e: any) => setLocalCity(e.target.value)} type="text" />
                                </Form.Group>
                              </Col>

                            </Row>
                            <Row className={cx(css({
                              '@media(max-width: 960px)': {
                                flexDirection: 'column'
                              }
                            }))}>
                              <Col>
                                {regionSelector}
                              </Col>
                              <Col>
                                <Form.Group controlId="postalCode" >
                                  <Form.Label>Postal Code</Form.Label>
                                  <Form.Control value={localPostalCode || ""} onChange={(e: any) => setLocalPostalCode(e.target.value)} type="text" />
                                </Form.Group>
                              </Col>
                            </Row>
                          </>)}
                        <Row>
                          <Col>
                            <Button disabled={saveShippingInformationDisabled} variant="primary" onClick={() => saveShippingInformation()}>
                              Confirm
                        </Button>
                          </Col>
                        </Row>
                      </Form>
                    </>
                    break;
                  case "YourFinalBalanceIsBeingPrepared":
                    body = (
                      <span>
                        Thank you for confirming your shipping address. We’re now preparing your final balance to include shipping fees and taxes for your delivery destination. We will notify you once your final balance is available to be paid shortly before your Vestaboard is scheduled to be produced.
                      </span>
                    )
                    break;
                  case "ConfirmYourOrderByPayingFinalBalance":
                    body = (
                      <div>
                        <p>Your final balance is ready to be paid. Click the blue button below to make payment and confirm your order for production now.</p>
                        {(dutyPaidTiming() === "DAP") ? (
                          <>
                            <p>Please note that you are liable to pay any inbound duties and taxes which your local customs authority deems appropriate. Duties and/or taxes will be collected by your courier service at time of delivery. Please confirm that you acknowledge and accept these terms.</p>
                            <Form.Check type={"checkbox"} id={`dapConfirmation`}>
                              <Form.Check.Input type={"checkbox"} checked={localDapConfirmation || false} onChange={(e) => {
                                setLocalDapConfirmation(e.target.checked);
                              }} />
                              <Form.Check.Label className="app-option-label">
                                <strong>I acknowledge</strong>
                              </Form.Check.Label>
                            </Form.Check>
                          </>
                        ) : <span />}
                        <Button
                          onClick={() => makePayment()}
                          disabled={(currentBalance(0) === 0) || (dutyPaidTiming() === "DAP" && !localDapConfirmation)}>Make payment
                        </Button>
                      </div>
                    )
                    break;
                  case "YourOrderIsConfirmed":
                    body = (
                      <span>
                        Thank you for your final payment. Your order is now confirmed and being processed for production and delivery within the next 90 days.
                      </span>
                    );
                    break;
                  case "YourVestaboardIsBeingMade":
                    body = (
                      <span>
                        Your order has been received and is currently in production. We will notify you when your Vestaboard has left our warehouse with final tracking information.
                      </span>
                    )
                    break;
                  case "YourVestaboardHasShipped":
                    body = (
                      <div>
                        <p>Your Vestaboard is on its way! Click the link below for tracking information. Thank you for being part of the Vestaboard community. We can't wait to see whom you inspire with Vestaboard.</p>
                        <Button onClick={() => { order.trackingLink ? window.location.href = order.trackingLink : null }} disabled={!order.trackingLink}>Track order</Button>
                      </div>
                    )
                    break;
                }

                switch (status) {
                  case "ConfirmYourShippingAddress":
                    header = "Confirm your shipping address"
                    break;
                  case "YourFinalBalanceIsBeingPrepared":
                    header = "Your final balance is being prepared"
                    break;
                  case "ConfirmYourOrderByPayingFinalBalance":
                    header = "Confirm your order by paying final balance"
                    break;
                  case "YourOrderIsConfirmed":
                    header = "Your order is confirmed"
                    break;
                  case "YourVestaboardIsBeingMade":
                    header = "Your Vestaboard is being made"
                    break;
                  case "YourVestaboardHasShipped":
                    header = "Your Vestaboard has shipped"
                    break;
                }

                return {
                  heading: (
                    <div className={cx("flex justify-between", css({
                      flex: 1,
                      '@media(max-width: 960px)': {
                        flexDirection: 'column',
                        '.tr': {
                          textAlign: 'left',
                          button: {
                            width: 'auto',
                            paddingLeft: 0
                          }
                        }
                      }
                    }))}>
                      <div className="self-stretch"><h3 className={css({
                        fontFamily: '"HelveticaNeueLTStd-Bd", sans-serif',
                        fontSize: '23px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: '0.27px',
                        color: "#34475b",
                        marginTop: 5,
                        '@media(max-width: 960px)': {
                          fontSize: 18,
                          fontWeight: 'bold',
                          marginBottom: 0
                        }
                      })}>{header}</h3></div>
                      <div className={cx("tr", css({
                        '@media(max-width: 960px)': {
                          display: !order.orderStatus || orderedOrderStatuses.indexOf(status) >= orderedOrderStatuses.indexOf(order.orderStatus) ? 'block' : 'none'
                        }

                      }))}><a target="_blank" className={cx(css({
                        width: 100,
                        textAlign: 'right',
                        fontSize: 14,
                        display: 'block'
                      }))} href="https://www.vestaboard.com/help/delivery">More info</a>
                      </div>
                    </div>
                  ),
                  render: <>
                    {body}
                  </>,
                  key: status
                }
              })}
            </VerticalStepper>
          </div>
        )}
        <div className={"w-40 dn db-l"}>
          {generalInfoBox}
        </div>
      </div>
      {
        generalInfoMobileShown && (
          <div className={"db-s dn-l"}>
            {generalInfoBox}
          </div>
        )
      }

    </Container >
  )
}
