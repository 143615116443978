import * as React from "react";

type NavigationProps = {}

export const Navigation: React.SFC<NavigationProps> = (props) => {
  return (
    <div>
    </div>
  )
}
